/*===========================================================
                        blog Details               
=============================================================*/

.blog-details {
    padding-top: rem(0);
    padding-bottom: rem(80);
    @include media(768) {
        padding-bottom: rem(130);
    }
    .post-hero {
        margin-bottom: rem(80);
        position: relative;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 1;
        @include media(768) {
            margin-bottom: rem(130);
        }
        .overlay {
            background-color: $color-black;
            opacity: 0.62;
        }
        .hero {
            padding-top: rem(120);
            padding-bottom: rem(80);
            text-align: center;
            max-width: 55rem;
            line-height: 1.13;
            margin: 0 auto;
            @include media(768) {
                padding-top: rem(250);
                padding-bottom: rem(200);
            }
        }
        .post__title {
            color: $color-white;
            font-size: 2.5rem;
            line-height: 1.3;
            font-weight: 300;
            margin-bottom: rem(20);
            @include media(768) {
                font-size: 3.75rem;
            }
        }
        .post__meta {
            color: $color-white;
            font-size: 1.313rem;
            font-weight: 400;
            line-height: 1.43;
        }
    }
    .post {
        .post-content {
            margin-bottom: rem(60);
        }
        p {
            &:not(:last-child) {
                padding-bottom: rem(30);
            }
        }
        h1 {
            padding-bottom: rem(25);
        }
        h2 {
            padding-bottom: rem(25);
        }
        h3 {
            padding-bottom: rem(25);
        }
        h4 {
            padding-bottom: rem(25);
        }
        h5 {
            padding-bottom: rem(25);
        }
        h6 {
            padding-bottom: rem(25);
        }
        blockquote {
            margin-top: rem(15);
            margin-bottom: rem(35);
            position: relative;
            &:before {
                content: "";
                background-image: url(../layout/blockquote.png);
                width: 60px;
                height: 60px;
                position: absolute;
                background-repeat: no-repeat;
                top: 0;
                left: 0;
            }
            p {
                font-family: "SF Pro Display";
                font-size: 1.313rem;
                font-weight: 400;
                line-height: 1.43;
                color: $color-black;
                padding-top: rem(50);
            }
        }
    }
    .meta-info {
        margin-bottom: rem(90);
        .meta-tags {
            color: $color-gray;
            font-size: 0.875rem;
            font-weight: 700;
            text-transform: uppercase;
        }
        a {
            color: $color-gray;
            transition: .3s;
            &:hover {
                color: $color-black;
            }
            &:first-child {
                margin-left: rem(10);
            }
            &:not(:last-child) {
                &:after {
                    content: ','
                }
            }
        }
    }
}