/*========================= hero universal style =========================*/

section[class^=hero-] {
    position: relative;
    z-index: 1;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.hero {
    &-wrapper {
        @include mediaMax(767) {
            text-align: center;
        }
    }
    &__title {
        font-size: rem(45);
        line-height: 1.2;
        font-weight: 300;
        margin-bottom: rem(20);
        @include media(992) {
            font-size: rem(70);
            line-height: 1.05;
        }
    }
    &__caption {
        font-size: rem(21);
        font-weight: 400;
        line-height: rem(30);
        margin-bottom: rem(30);
    }
    &__wave {
        position: absolute;
        bottom: 0;
        width: 100%;
        &--svg .st0{
            fill: #fff;
            opacity: 1;
        }
    }
}