/*------------------------
	font icons
-------------------------*/

.nc-icon.nc-layers-3:before {
  content: "\ea02";
}

.nc-icon.nc-watch-circle:before {
  content: "\ea03";
}

.nc-icon.nc-chart-bar-33:before {
  content: "\ea04";
}

.nc-icon.nc-p-system-update:before {
  content: "\ea05";
}

.nc-icon.nc-touch-id:before {
  content: "\ea06";
}

.nc-icon.nc-p-heart:before {
  content: "\ea07";
}

.nc-icon.nc-triangle-right-17:before {
  content: "\ea08";
}

.nc-icon.nc-tail-right:before {
  content: "\ea09";
}

.nc-icon.nc-tail-left:before {
  content: "\ea0a";
}

.nc-icon.nc-send:before {
  content: "\ea0b";
}

.nc-icon.nc-app:before {
  content: "\ea0c";
}

.nc-icon.nc-stre-right:before {
  content: "\ea0d";
}

.nc-icon.nc-minimal-right:before {
  content: "\ea0e";
}

.nc-icon.nc-minimal-left:before {
  content: "\ea0f";
}

.nc-icon.nc-heart-2:before {
  content: "\ea10";
}

.nc-icon.nc-layers-3-2:before {
  content: "\ea11";
}

.nc-icon.nc-code-editor:before {
  content: "\ea12";
}

.nc-icon.nc-responsive:before {
  content: "\ea13";
}

.nc-icon.nc-web-design:before {
  content: "\ea14";
}

.nc-icon.nc-apple:before {
  content: "\ea15";
}

.nc-icon.nc-ic_android_48px:before {
  content: "\ea16";
}

.nc-icon.nc-favourite-31:before {
  content: "\ea17";
}

