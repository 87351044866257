/**! 09. hero **/
@import "hero/hero";
/**! 10. cta **/
@import "cta/cta";
/**! 11. facts **/
@import "facts/facts";
/**! 12. faq **/
@import "faq/faq";
/**! 13. features **/
@import "features/features";
/**! 14. newsletter **/
@import "newsletter/newsletter";
/**! 15. testimonial **/
@import "testimonial/testimonial";
/**! 16. switchable **/
@import "switchable";
/**! 17. client-brand **/
@import "client-brand";
/**! 18. video-section **/
@import "video-section";
/**! 19. working-process **/
@import "working-process";
/**! 20. pricing **/
@import "pricing";
/**! 21. page header**/
@import "page-header";
/**! 22. pagination **/
@import "pagination";

