/*========================= Hero-2 =========================*/

section[class^=hero-two] {
    overflow: visible;
}

.hero-two {
    background-color: $color-green;
    .shape-1 {
        position: absolute;
        width: 40%;
        top: 0;
        left: 0;
        animation: db-fade 8s infinite alternate;

        .st0 {
            fill: #24BD65;
        }
        @include media(768) {
            width: 18%;
        }
        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            width: 16%;
            height: 30%;
        }
    }
    .shape-2 {
        position: absolute;
        width: 55%;
        bottom: 0;
        right: 0;
        animation: db-fade 8s infinite alternate;
        animation-delay: 5s;
        .st0 {
            fill: #24BD65;
        }
        @include media(768) {
            width: 35%;
        }
        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            width: 35%;
            height: 60%;
        }
    }
    .hero {
        height: 47rem;
        @include media(500) {
            height: 50rem;
        }
        @include media(991) {
            height: 55.563rem;
        }
        &-content {
            position: relative;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                display: block;
            }
            @include media(992) {
                padding-top: rem(150);
                padding-bottom: rem(100);
            }
            .hero__title {
                @include media(992) {
                    font-size: 3.75rem;
                    line-height: 1.13;
                }
            }
            .additional__feature {
                margin-top: rem(20);
                color: $color-white;
                font-size: rem(14);
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    display: block;
                }
            }
        }
        .hero-media {
            .hero-media__img {
                box-shadow: 0 32px 54px rgba(0, 0, 0, 0.13);
                border-radius: 10px;
            }
        }
    }
}