/*===========================================================
                additional style for home-6                   
=============================================================*/

/*-------------- client-brand--------------*/
.home-6 {
    .client-brand-1 {
        padding: rem(150) 0 0;
        @include media(992) {
            padding: 330px 0 0;
        }
    }
    
}
