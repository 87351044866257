/*========================= Hero-1 =========================*/

.hero-one {
    .overlay {
        background: linear-gradient(-46deg, #2313b8 0%, #53a0fd 50%, #09bf6b 100%);
        opacity: 0.7;
    }
    .hero {
        &-content {
            position: relative;
            @include media(992) {
                padding: rem(210) 0 rem(360);
            }
            @include mediaMax(991) {
                margin: 0 auto;
                text-align: center;
            }
        }
        .hero-media {
            text-align: center;
            @include media(992) {
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: -1;
                text-align: right;
            }
        }
    }
}