// colors
$color-primary: #4777f4;
$color-white: #ffffff;
$color-black: #2d3136;
$color-gray: #818992;
$color-dark-gray: #2a2f36;
$bg-dark: #2a2f36;
$color-yellow: #f8e71c;
$color-blue: #4777f4;
$color-green: #27ae60;
$color-violet: #9b59b6;
$color-red: #e74c3c;
$color-firoza: #16a085;
$color-orange: #fe9815;