/*===========================================================
                        download                  
=============================================================*/

.download {
    .hero-content {
        text-align: center;
        .hero__title {
            font-weight: 300;
        }
        .hero__caption {
            font-size: 1.313rem;
            font-weight: 400;
            line-height: 1.43;
            a {
                text-decoration: underline;
                transition: .3s;
            }
        }
    }
    .download-media {
        text-align: center;
        position: relative;
        @include mediaMax(991) {
            margin-right: rem(15);
            margin-left: rem(15);
        }
    }
}

/*-------------- downlopad page-1--------------*/

.download-1 {
    background-image: linear-gradient(-45deg, #2f1ce0 0%, #53a0fd 50%, #51eca5 100%);
    .hero-content {
        text-align: center;
        padding-top: rem(150);
        padding-bottom: rem(70);
        @include media(768) {
            padding-top: rem(210);
            padding-bottom: rem(120);
        }
        .hero__title {
            font-size: 3rem;
            color: $color-white;
        }
        .hero__caption {
            color: $color-white;
            a {
                color: $color-white;
                &:hover {
                    color: darken($color: $color-white, $amount: 10)
                }
            }
        }
    }
}

/*-------------- downlopad page-2--------------*/

.download-2 {
    .hero-content {
        max-width: rem(670);
        margin: 0 auto;
        padding-top: rem(180);
        @include media(768) {
            padding-top: rem(220);
        }
        .hero__title {
            margin-bottom: rem(30);
        }
        .hero__caption {
            color: $color-black;
            a {
                &:hover {
                    color: lighten($color: $color-blue, $amount: 5)
                }
            }
        }
        .download__icon {
            background: $color-primary;
            border-radius: 100px;
            box-shadow: 0 0 0 2.5rem rgba($color-primary, .05), 0 0 0 1.25rem rgba($color-primary, .05);
            margin-bottom: rem(40);
            @include media(768) {
                margin-bottom: rem(80);
            }
            .icon {
                background-image: url(..//layout/file_download.svg);
                width: 6.75rem;
                height: 6.75rem;
                line-height: 6.75rem;
                border-radius: 100px;
                background-repeat: no-repeat;
                background-position: center center;
                display: block;
                @include media(768) {
                    width: 7.75rem;
                    height: 7.75rem;
                    line-height: 7.75rem;
                }
            }
        }
    }
}