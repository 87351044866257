/*========================= newsletter-one =========================*/

.newsletter-one {
    background-color: $color-black;
    background-position: center center;
    padding: rem(110) 0;
    @include media(768) {
        padding: rem(130) 0;
    }
    .newsletter-wrapper {
        max-width: rem(830);
        margin: 0 auto;
        text-align: center;
        @include media(992) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;
        }
    }
    .newsletter-media {
        margin-bottom: rem(50);
        @include media(992) {
            margin-bottom: 0;
            margin-right: rem(80);
            flex: 0 0 rem(305);
        }
    }
    .newsletter-optin {
        @include media(992) {
            text-align: left;
        }        
        &__title {
            color: $color-white;
            margin-bottom: rem(25);
            @include mediaMax(767) {
                font-size: rem(35)
            }
            @include media(992) {
                max-width: rem(425);
            }
        }
        &__description {
            color: $color-white;
            margin-bottom: rem(43);
        }
        .btn__submit--icon {
            font-size: rem(30);
            line-height: rem(55);
            @include media(768) {
                line-height: rem(70)
            }
        }
    }
}
