/*------- typography ------- */

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  color: $color-black;
  font-weight: 400;
}

h1 {
  font-size: rem(48);
  line-height: 1.05;
}

h2 {
  font-size: rem(40);
  line-height: 1.16;
  font-weight: 300;
  @include media(768) {
    font-size: rem(48);
  }
}

h3 {
  font-size: rem(34);
  line-height: 1.25;
}

h4 {
  font-size: rem(28);
  line-height: 1.25;
}

h5 {
  font-size: rem(24);
  line-height: 1.25;
}

h6 {
  font-size: rem(21);
  line-height: 1.28;
}

p {
  font-size: rem(17);
  line-height: 1.53;
  color: $color-gray;
}
