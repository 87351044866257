/*========================= Hero-10 =========================*/
.hero-ten {
    background-image: linear-gradient(-45deg, #2f1ce0 0%, #53a0fd 50%, #51eca5 100%);
    .hero-content {
        padding-bottom: rem(120);
        .hero__title {
            font-size: 3.75rem;
            line-height: 1.13;
        }
        .db-btn__outline {
            color: $color-white;
            border-radius: 5px;
            &:before {
                background: $color-white;
            }
            &:hover {
                color: $color-black;
                border-color: $color-white;
            }
        }
    }
    .hero-media {
        text-align: center;
        position: relative;
        @include mediaMax(991) {
            margin-right: rem(15);
            margin-left: rem(15);
        }
        .watch-video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            i {
                color: $color-white;
                background-color: rgba($color-primary, .6);
                border-radius: 100px;
                width: 5.688rem;
                height: 5.688rem;
                line-height: 5.688rem;
                font-size: rem(24);
                text-align: center;
                transition: .3s;
            }
        }
    }
}