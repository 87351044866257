/**!**************/
/**! Contents **/
/**! 01. Vecndor **/
/**! 02. Reset **/
/**! 03. Canvas **/
/**! 04. Typography **/
/**! 05 Buttons **/
/**! 06. Form **/
/**! 07. navigation **/
/**! 08. footer **/
/**! 09. hero **/
/**! 10. cta **/
/**! 11. facts **/
/**! 12. faq **/
/**! 13. features **/
/**! 14. newsletter **/
/**! 15. testimonial **/
/**! 16. switchable **/
/**! 17. client-brand **/
/**! 18. video-section **/
/**! 19. working-process **/
/**! 20. pricing **/
/**! 21. page header **/
/**! 22. pagination **/
/**! 23. general **/
/**! 24. home **/
/**! 25. reviews **/
/**! 26. downloads **/
/**! 27. blog **/
/**! 28. error page **/
/**! 29. theme dark mode **/
/**! 30. pattern **/
/**! 31. color primary **/
/**! 32. color gradient **/
/**!**************/
/**!**************/
/**!**************/

@import "helpers/_helpers.scss";

/*********************************************
    vendors
/*********************************************/
@import "vendors/_vendors.scss";

/*********************************************
    base
/*********************************************/
@import "base/_base.scss";

/*********************************************
    layout
/*********************************************/
@import "layout/_layout.scss";

/*********************************************
    modules
/*********************************************/
@import "modules/_modules.scss";

/*********************************************
    templates
/*********************************************/
@import "templates/_templates.scss";

/*********************************************
    themes
/*********************************************/
@import "themes/themes.scss";
