/*========================= cta-2 =========================*/
.cta-two {
    .cta-content {
        text-align: center;
        margin-bottom: rem(80);
        @include media(992) {
            max-width: rem(660);
            margin: 0 auto rem(130);
        }
    }
    .cta__title {
        max-width: rem(530);
        margin: 0 auto rem(30);
    }
    .cta__description {
        margin-bottom: rem(45);
    }
    .cta-media {
        text-align: center;
    }
    .db-btn{
        background: $color-primary;
        color: $color-white;
    }
}
