/*========================= Testimonial-one =========================*/

.testimonial-one {
    overflow: hidden;
    background: linear-gradient(-45deg, #2f1ce0 0%, #53a0fd 50%, #51eca5 100%);
    position: relative;
    margin-top: rem(265);
    padding-bottom: rem(120);
    padding-top: rem(260);
    z-index: 1;
    @include media(992) {
        padding-top: rem(385);
        padding-bottom: rem(160);
    }
    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: rotate(-53deg);
        background-color: #000;
        opacity: 0.03;
        height: 600px;
    }
    &:before {
        bottom: -200px;
        left: 0;
        transform-origin: bottom left;
    }
    &:after {
        top: 250px;
        right: 0;
        transform-origin: top right;
        z-index: -1;
    }
    .slick-arrow {
        top: auto;
        bottom: 0px;
        left: 50%;
        margin-left: rem(-20);
        @include media(768) {
            left: 0;
        }
        .nextArrow {
            left: 30px;
            right: auto;
            @include media(768) {
                left: 65px;
            }
        }
        .prevArrow {
            left: -30px;
            @include media(768) {
                left: 15px;
            }
        }
    }
}
