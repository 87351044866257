/*========================= client-brand-1 =========================*/

.client-brand-1 {
    padding-top: rem(70);
    .client-brand-title {
        text-align: center;
        margin-bottom: rem(45);
        &__caption {
            color: $color-gray;
        }
    }
    .client-brand-wrapper {
        text-align: center;
        position: relative;
        @include media(576) {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
        }
        @include media(1200) {
            justify-content: space-between;
        }
        @include media(992) {
            &:before {
                content: "";
                position: absolute;
                width: rem(100);
                height: 1px;
                background: #e2e6ea;
                left: 50%;
                bottom: 0;
                transform: translate(-50%, 65px);
            }
        }
        .client {
            @include mediaMax(575) {
                display: block;
                &:not(:last-child) {
                    margin-bottom: rem(30);
                }
            }
            @include mediaMax(1199) {
                margin-bottom: rem(30);
                margin-right: rem(15);
                margin-left: rem(40);
            }
        }
    }
}
