/*========================= pricing table-1 =========================*/

.pricing-one {
    padding: rem(80) 0;
    @include media(992) {
        padding: rem(120) 0 rem(130);
    }
    background-color: #f2f4f6;
    div[class^=col-] {
        &:last-child {
            .prcing-table {
                margin-bottom: 0;
            }
        }
    }
    .prcing-table {
        background-color: $color-white;
        text-align: center;
        padding: rem(50) rem(50) rem(70) rem(50);
        border-radius: 10px;
        @include mediaMax(767) {
            margin-bottom: rem(30);
        }
        @include media(768) {
            padding: rem(20) rem(20) rem(40);
        }
        @include media(992) {
            padding: rem(30) rem(20) rem(50);
        }
    }
    .prcing-header {
        margin-bottom: rem(40);
    }
    .currency {
        vertical-align: top;
        font-size: rem(21);
        line-height: rem(50);
        color: $color-black;
    }
    .value {
        font-size: rem(60);
        color: $color-black;
    }
    .duration {
        font-size: rem(21);
        position: relative;
        color: $color-black;
    }
    .duration:before {
        content: "/";
    }
    .prcing-table__plan-name {
        font-size: 1.125rem;
        font-weight: 500;
        border-radius: 100px;
        background-color: #f2f4f6;
        padding: rem(4) rem(14);
        color: $color-black;
    }
    .plan-features {
        margin-bottom: rem(40);
    }
    .plan-features__list {
        line-height: rem(34);
        color: $color-black;
    }
    .db-btn__type-md {
        background: $color-primary;
        color: $color-white;
        @media only screen and (min-width: 768px) and (max-width: 991px) {
            font-size: rem(15);
            min-width: inherit;
            padding-right: rem(10);
            padding-left: rem(10);
        }
    }
}