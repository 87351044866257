/*========================= Hero-4 =========================*/

.hero-four {
    .hero {
        &-wrapper {
            @include media (768) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top: rem(145);
            }
        }
        &-content {
            @include media (768) {
                padding: 0;
                padding-bottom: rem(95);
            }
        }
        &-media {
            position: relative;
            right: -40px;
            @include media (768) {
                right: -80px;
            }
            &:before {
                content: '';
            }
        }
        .hero__circle {
            position: absolute;
            bottom: -10px;
            width: 100%;
            z-index: -1;
            opacity: 0.05;
            @include mediaMax(767) {
                top: calc(50% - 80px);
                left: calc(50% - 30px);
                transform: translate(-50%, -50%);
            }
            @include media(768) {
                top: calc(50% - 40px);
                right: -90px;
                transform: translateY(-50%);
            }
        }
    }
}