/***********************************************************
                    --theme color--
***********************************************************/

.theme-dark {
    background-color: $color-dark-gray;
    color: $color-white;
    .main-wrapper {
        background-color: $color-dark-gray;
    }
    /*-- typography --*/
    h1 {
        color: $color-white;
    }
    h2 {
        color: $color-white;
    }
    h3 {
        color: $color-white;
    }
    h4 {
        color: $color-white;
    }
    h5 {
        color: $color-white;
    }
    h6 {
        color: $color-white;
    }
    p {
        color: $color-gray;
    }
    /*-- modules--*/
    .navigation__btn-fill {
        .nav-cta-btn {
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.18);
        }
    }
    .hero__wave--svg {
        .st0 {
            fill: $color-dark-gray;
        }
    }
    .client-brand-1 {
        .client-brand-wrapper {
            &:before {
                background: #454a52;
            }
        }
    }
    .features-two {
        .feature__icon {
            border: 0.188rem solid rgba(255, 255, 255, 0.09);
        }
        .feature__list {
            &:hover {
                .feature__icon {
                    border: 0.188rem solid rgba(255, 255, 255, 0.2);
                }
            }
        }
    }
    .working-process-list-item:not(:last-child):before {
        color: #3d434c;
    }
    .switchable {
        .db-btn__outline {
            color: $color-white;
            border-radius: 5px;
            &:before {
                background: $color-white;
            }
            &:hover {
                color: $color-black;
                border-color: $color-white;
            }
        }
    }
    .pricing-one {
        background-color: #30353d;
    }
    .testimonial-two {
        background-color: $color-dark-gray;
        &:before,
        &:after {
            content: none;
        }
    }
    .faq-two {
        .faq-header {
            button {
                color: $color-white;
            }
            h5 {
                border-color: #454a52;
            }
        }
    }
    .newsletter-one {
        background-color: #2c3138;
    }
    .newsletter-two {
        background-color: $color-dark-gray;
    }
    .cta-one {
        background-color: $color-primary;
        border-bottom: none;
        .cta__description {
            color: $color-white;
        }
        .db-btn {
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.18);
            &:before {
                background: rgba(0, 0, 0, 0.05);
            }
            &:hover {
                &:before {
                    background: rgba(0, 0, 0, 0.05);
                }
                color: $color-black;
            }
        }
    }
    .footer-widget {
        li {
            a {
                color: $color-white;
            }
        }
    }
    .footer-two {
        background-color: #30353d;
    }
}