/*========================= Hero-9 =========================*/

.hero-nine {
    .hero-content {
        max-width: 34rem;
        padding-top: rem(270);
        padding-bottom: rem(80);
        @include mediaMax(991) {
            padding-top: rem(130);
            margin: 0 auto;
            text-align: center;
        }
        .hero__title {
            font-size: 3.75rem;
            line-height: 1.13;
        }
    }
    .db-btn {
        margin-bottom: rem(185);
        @include mediaMax(991) {
            margin-bottom: rem(40);
        }
    }
    .hero-media {
        @include mediaMax(991) {
            text-align: center;
            margin-right: rem(15);
            margin-left: rem(15);
        }
        @include media(992) {
            position: absolute;
            top: 0;
            right: -15%;
            width: 57%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: rem(30);
        }
        @include media(1601) {
            right: -5%;
            width: 50%;
        }
        &__img {
            width: 100%;
            img {
                box-shadow: 0 1.25rem 4rem rgba(0, 0, 0, 0.2);
            }
        }
    }
    .watch-video {
        display: flex;
        align-items: center;
        @include mediaMax(991) {
            justify-content: center;
        }
        .btn__play {
            margin-right: rem(18);
        }
        i {
            color: $color-white;
            background-color: $color-primary;
            border-radius: 100px;
            width: 3.813rem;
            height: 3.813rem;
            line-height: 3.813rem;
            text-align: center;
            transition: .3s;
        }
        &__text {
            display: flex;
            flex-direction: column;
            font-size: 1rem;
            font-weight: 400;
            &--big {
                font-family: "SF Pro Display";
                font-size: 1.5rem;
            }
        }
    }
}
